<template>
	<div class='input-components'>
		<template v-if='!isUndefined'>
			<component-toolbar @remove='removeComponent' 
				@forward='++zIndexValue'
				@backward='--zIndexValue'
				@duplicate='$emit("duplicate")'
				:forwardEnabled='options.zIndex < nbComponents'
				:backwardEnabled='options.zIndex > 1'/>
			<font-picker v-model='fontValue'/>

			<b-input v-model='textValue' type="textarea" :class='options.justification' :placeholder='$t("TEXTS.PLACEHOLDER")'></b-input>
			<div class='row is-justify-content-center'>
				<b-radio-button v-model="justificationIndex"
					:native-value="0" type="is-outlined">
					<b-icon icon="format-align-left"></b-icon>
				</b-radio-button>
				<b-radio-button v-model="justificationIndex"
					:native-value="1" type="is-outlined">
					<b-icon icon="format-align-center"></b-icon>
				</b-radio-button>
				<b-radio-button v-model="justificationIndex"
					:native-value="2" type="is-outlined">
					<b-icon icon="format-align-right"></b-icon>
				</b-radio-button>
			</div>

			<number-picker :min="0" :max="Math.max(sizeValue, Math.max(width, height)/2)" v-model="sizeValue" unit='px'
				:prepend="$t( 'COMMON.SMALL' )" :append="$t( 'COMMON.BIG' )"/>

			<position-picker :width="width" :height="height" v-model="positionValue"/>

			<color-picker v-model="colorValue" :dropColorEnabled='true'/>

			<number-picker :min="-180" :max="180" v-model="angleValue" unit='°'
				:prepend="'-180°'" :append="'+180°'"/>

			<b-field>
				<b-switch v-model="backgroundEnabledValue">
					<b-icon icon="format-paint"></b-icon>
				</b-switch>
				<color-picker v-model="backgroundColorValue" :dropColorEnabled='true' :disabled='!backgroundEnabledValue'/> 

			</b-field>
		</template>
	</div>
</template>


<script>
import ColorPicker from '@/components/pickers/ColorPicker.vue';
import NumberPicker from '@/components/pickers/NumberPicker.vue';
import FontPicker from '@/components/pickers/FontPicker.vue';
import PositionPicker from '@/components/pickers/PositionPicker.vue';
import ComponentToolbar from '@/components/pickers/ComponentToolbar.vue';

export default {
	name: 'text-input-component',
	components: { ColorPicker, NumberPicker, FontPicker,PositionPicker, ComponentToolbar },
	props: {
		width: Number,
		height: Number,
		fieldLabel: String,
		// TODO add values validators
		options: {
			size: { type: Number, default: 10 },
			position: {
				x: { type: Number, default: 0 },
				y: { type: Number, default: 0 },
			},
			angle: { type: Number, default: 0 },
			text: { type: String, default: '' },
			font: { type: String, default: '' },
			color: { type: String, default: '#000000' },
			justification: { type: String, default: 'center' },
			zindex: { type: Number, default: 0 },
			background: {
				enabled: { type: Boolean, default: false }, // todo disable color picker
				color: { type: String, default: '#000000' },
				padding: { type: Number, default: 3 }, // todo selector
			}
		},
		nbComponents: { type: Number, default: 1 },
	},
	data: () => ({
	}),
	computed: {
		sizeValue: {
			get: function() { return this.options.size; },
			set: function(newValue) {
				if(newValue === this.options.size) return;
				this.options.size = newValue;
				this.$emit('update', { size: newValue })}
		},
		textValue: {
			get: function() { 
				if (this.options.text === ' ') return '';
				return this.options.text;
			},
			set: function(newValue) {
				if (newValue === '') newValue = ' ';
				if(newValue === this.options.text) return;
				this.options.text = newValue;
				this.$emit('update', { text: newValue })
			}
		},
		fontValue: {
			get: function() { return this.options.font; },
			set: function(newValue) {
				if (newValue === this.options.font) return;
				this.options.font = newValue;
				this.$emit('update', { font: newValue })}
		},
		colorValue: {
			get: function() { return this.options.color; },
			set: function(newValue) {
				if (newValue === this.options.color) return;
				this.options.color = newValue;
				this.$emit('update', { color: newValue })}
		},
		justificationValue: {
			get: function() { return this.options.justification; },
			set: function(newValue) {
				if (newValue === this.options.justification) return;
				this.options.justification = newValue;
				this.$emit('update', { justification: newValue })}
		},
		positionValue: {
			get: function() { return this.options.position; },
			set: function(newValue) {
				if (newValue.x !== this.options.position.x || newValue.y !== this.options.position.y) 
					this.$emit('update', { position: newValue })}
		},
		angleValue: {
			get: function() { return this.options.angle; },
			set: function(newValue) {
				if (newValue === this.options.angle) return;
				this.options.angle = newValue;
				this.$emit('update', { angle: newValue })}
		},
		justificationIndex: {
			set: function(value) {
				if (value === ['left', 'center', 'right'].indexOf(this.options.justification)) return;
				const newJustification = ['left', 'center', 'right'][value];
				this.options.justification = newJustification;
				this.$emit('update', { 'justification': newJustification });
			},
			get: function() {
				return ['left', 'center', 'right'].indexOf(this.options.justification);
			}
		},
		zIndexValue: {
			get() { return this.options.zIndex; },
			set(newValue) {
				newValue = Math.max(newValue, 1);
				newValue = Math.min(newValue, this.nbComponents);
				if (newValue === this.options.zIndex) return;
				this.options.zindex = newValue;
				this.$emit('update', { zIndex: newValue });
			}
		},
		backgroundEnabledValue: {
			get() { return this.options.background.enabled },
			set(newValue) {
				if (newValue === this.options.background.enabled ) return;
				this.options.background.enabled = newValue;
				this.$emit('update', { background: { enabled: newValue }});
			}
		},
		backgroundColorValue: {
			get() { return this.options.background.color },
			set(newValue) {
				if (newValue === this.options.background.color ) return;
				this.options.background.color = newValue;
				this.$emit('update', { background: { color: newValue }});
			}
		},
		isUndefined: function() {
			return Object.keys(this.options).length == 1;
		}
	},
	methods: {
		removeComponent() {
			// TODO confirm box ? Oui en attendant le undo/redo
			this.$emit('remove');
		}
	}
};
</script>

<style>
.control.left textarea {
	text-align: left;
}

.control.right textarea {
	text-align: right;
}

.control.center textarea {
	text-align: center;
}
</style>
