<template>
<div style='flex-grow: 1'>
	<b-input id='qrcode-input-url' style='margin-top:10px;margin-bottom:10px' v-model="urlValue"></b-input>
	<color-picker v-model="colorValue" :key='rerenderColorComponent' />
	<symbol-picker v-model='symbolValue' :symbolPicker='true'/>
</div>	
</template>


<script>

import ColorPicker from '@/components/pickers/ColorPicker.vue';
import SymbolPicker from '@/components/pickers/SymbolPicker.vue';

export default {
	name: 'qrcode-picker',	
	components: { ColorPicker, SymbolPicker },
	props: {
		value: { 
			url: String, 
			color: String,
			symbol: { 
				id: String,
				colors: { type: Object }
			}
		},
	},
	data: () => ({
		rerenderColorComponent: 0
	}),
	computed: {
		symbolValue: {
			get: function() {
				return this.value.symbol
			},
			set: function(newValue) {
				if (newValue === this.value.symbol) return;
				this.$emit('input', { symbol: newValue });
			}
		},
		urlValue: {
			get: function() {
				return this.value.url;
			},
			set: function(newValue) {
				if (newValue === this.value.url) return;
				this.$emit('input', { url: newValue });
			}
		},
		colorValue: {
			get: function() {
				this.rerenderColorComponent;
				return this.value.color;
			},
			set: function(newValue) {
				if (newValue === this.value.color) return;
				this.$emit('input', { color: newValue });
			}
		}
	},
}
</script>

<style scoped>
</style>
