import QRCode from 'qrcode'

export const createSvgQrCode = (value) => {
    return new Promise((resolve, reject) => {
    if (value == '') reject();

    const qrCode = QRCode.create(value, {
        errorCorrectionLevel: 'H',
        width: 128,
        version: 100
    });
    
    const size = qrCode.modules.size;
    const data = qrCode.modules.data;

    const qrCodeContainer = document.createElement('div');
    const qrCodeSvg = document.createElement('svg');
    qrCodeSvg.setAttribute('width', `${size}`);
    qrCodeSvg.setAttribute('height', `${size}`);
    qrCodeSvg.setAttribute('viewBox', `0 0 ${size} ${size}`);
    qrCodeSvg.setAttribute('xmlns:dc', "http://purl.org/dc/elements/1.1/");
    qrCodeSvg.setAttribute('xmlns:cc', "http://creativecommons.org/ns#");
    qrCodeSvg.setAttribute('xmlns:rdf', "http://www.w3.org/1999/02/22-rdf-syntax-ns#");
    qrCodeSvg.setAttribute('xmlns:svg', "http://www.w3.org/2000/svg");
    qrCodeSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg");
    qrCodeSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink");
    qrCodeSvg.setAttribute('xmlns:sodipodi', "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd");
    qrCodeSvg.setAttribute('xmlns:inkscape', "http://www.inkscape.org/namespaces/inkscape");
    qrCodeSvg.setAttribute('xml:space', "preserve");
    qrCodeSvg.setAttribute('version', '1.1'),

    qrCodeContainer.appendChild(qrCodeSvg);

    let newRow = false
    let lineLength = 0

    for (let i = 0; i < data.length; i++) {
      const col = Math.floor(i % size)
      const row = Math.floor(i / size)

      if (!col && !newRow) newRow = true

      if (data[i]) {
        lineLength++
        
        if (!(i > 0 && col > 0 && data[i - 1])) {
          newRow = false
        }

        if (!(col + 1 < size && data[i + 1])) {
          const rect = document.createElement('rect');
          rect.setAttribute('y', row)
          rect.setAttribute('x', 1 + col - lineLength);
          rect.setAttribute('height', 1);
          rect.setAttribute('width', lineLength)
          qrCodeSvg.appendChild(rect);
          lineLength = 0
        }
      }
    }
    let qrCodeStr = qrCodeContainer.innerHTML.replace('viewbox', 'viewBox');

    const parser = new DOMParser();
    const doc = parser.parseFromString(qrCodeStr, "image/svg+xml");
    resolve(qrCodeStr);
  });
}
