<template>
    <div>
        <vida-navbar active='welcome'/>
        <section class="hero is-primary is-medium">
            <div class="hero-body">
                <h1 class="title"><instance-name/></h1>
                <p class="subtitle">{{$localConfig.description}}</p>
            </div>
        </section>
        <section>
            <div class='container padding-30'>
                <h2 class="title">{{ $t('WELCOME.TEMPLATES_TITLE') }}</h2>
                <p class='subtitle'>{{ $t('WELCOME.TEMPLATES_SUBTITLE') }}</p>
                <div class='container is-flex is-flex-wrap-wrap is-justify-content-space-around'>

                    <div class="gallery-card card box is-flex is-flex-direction-column is-justify-content-space-between"
                        style="width: 300px"
                        v-for="template in templates" :key="template.id" @click='openEditor(template.id)'>
                        <div class="card-image">
                            <figure class="image contain-image">
                                <lazy-load-image :src="'/static/templates/' + template.preview" :alt="template.description"/>
                            </figure>
                        </div>
                        <div class="card-content">
                            <!-- {{ template.description }} -->
                        </div>
                        <footer class="card-footer">
                            <b-button type="is-primary" expanded tag="router-link" 
                                :to="{ name: 'editor', params: { 'lang': $i18n.locale, 'templateId': template.id }}">
                                {{ $t('BUTTONS.EDIT') }}
                            </b-button>
                        </footer>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>

<script>
import templates from '@/assets/local/data/templates.json';
import VidaNavbar from '@/components/navbar'
import LazyLoadImage from '@/components/lazyloadimage.vue';
import InstanceName from '@/components/ui/instance-name.vue';

import { showSnackbarOnRedirection } from '@/plugins/utils.js';

export default {
	name: 'welcome',
    metaInfo: function() {
        return ({
            title: this.$localConfig.id,
            titleTemplate: '%s | Aktivisda.earth',
            meta: [
                { vmid: 'description', name: 'description', content: `${this.$localConfig.description}. Rendu possible grâce au site open source Aktivisda.`  },
            ]
        });
    },
	components: { VidaNavbar, InstanceName, LazyLoadImage },
	data: () => ({
        templates
	}),
    methods: {
        openEditor( templateId) {
            if (this.$router.currentRoute.name === 'editor') return;
			this.$router.push({  name: 'editor', params: {lang: this.$i18n.locale, templateId }});
        }
    },
    beforeMount: () => {
        let counter = templates.length;

        // While there are elements in the templates
        while (counter > 0) {
            // Pick a random index
            let index = Math.floor(Math.random() * counter);

            // Decrease counter by 1
            counter--;

            // And swap the last element with it
            let temp = templates[counter];
            templates[counter] = templates[index];
            templates[index] = temp;
        }

    },
    mounted: function(){
		showSnackbarOnRedirection(this);
        document.dispatchEvent(new Event("x-app-rendered"));
    },
};
</script>

<style scoped>
.container.padding-30 {
    padding: 20px;
}
</style>
