'use strict'

import symbols from '@/assets/local/data/symbols.json';
import localConfig from '@/assets/local/localconfig.json';
import { createSvgQrCode } from '@/plugins/qrcode.js'
import { colorSvgString, loadSvg, svgToDom, colorsChanged } from '@/vida/components/svgutils.js';

export default class QrcodeComponent {

    constructor() {
    }

    toJson() {
        if (!this.options) {
            return {}
        }
        
        return {
            url: this.options.url,
            color: this.options.color,
            symbol: {
                id: this.options.symbol.id,
                colors: this.options.symbol.colors,
            }
        }
    }

    init() {
        const randomSymbolIndex = Math.floor(Math.random()*symbols.length);
        const randomSymbol = symbols[randomSymbolIndex];
        
        const options = {
            url: localConfig.url, 
			color: '#000000',
			symbol: { 
				id: randomSymbol['id'],
				colors: randomSymbol['colors']
            }
        }
        return this.update(options);
    }

    mergeSvg() {
        const symbolDom = svgToDom(this.symbolColoredString);
        const qrDom = svgToDom(this.baseQrCodeString);
        
        const symbolViewbox = { x: 0, y: 0, width: 0, height: 0}
        symbolViewbox.x = symbolDom.rootElement.viewBox.baseVal.x;			
        symbolViewbox.y = symbolDom.rootElement.viewBox.baseVal.y;			
        symbolViewbox.width = symbolDom.rootElement.viewBox.baseVal.width;			
        symbolViewbox.height = symbolDom.rootElement.viewBox.baseVal.height;
        

        const resultSize = 300;
        const symbolMaxSize = 0.5*resultSize;

        const symbolScale = symbolMaxSize/Math.max(symbolViewbox.width, symbolViewbox.height);
        const translateX = (300 - symbolViewbox.width*symbolScale)/2
        const translateY = (300 - symbolViewbox.height*symbolScale)/2
        
        const qrCodeBackground = document.createElement('rect');
        qrCodeBackground.setAttribute('style', 'fill:#ffffff');
        qrCodeBackground.setAttribute('width', '300');
        qrCodeBackground.setAttribute('height', '300');
        qrCodeBackground.setAttribute('x', '0');
        qrCodeBackground.setAttribute('y', '0');

        const qrCodeGroup = document.createElement('g');
        const qrcodeSize = qrDom.rootElement.viewBox.baseVal.width; 
        qrCodeGroup.setAttribute('transform', `scale(${resultSize/qrcodeSize})`)
        qrCodeGroup.setAttribute('style', `fill:${this.options.color}`)

        qrCodeGroup.replaceChildren(...qrDom.rootElement.children);

        const symbolGroup = document.createElement('g');
        symbolGroup.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${symbolScale})`)

        const symbolBackground = document.createElement('path')
        symbolBackground.setAttribute('d', this.options.symbol.backgroundHull)
        symbolBackground.setAttribute('style', 'fill:#ffffff')

        symbolGroup.replaceChildren(symbolBackground, ...symbolDom.rootElement.children);
        
        this.qrcodeResultDom = document.createElement('div');
        const svgns = "http://www.w3.org/2000/svg";
        const svgResult = document.createElementNS(svgns, 'svg')
        svgResult.setAttribute('viewBox', '0 0 300 300')
        svgResult.setAttribute('width', '300')
        svgResult.setAttribute('height', '300')
        svgResult.setAttribute('style',"background-color:white")
        svgResult.setAttribute('xmlns:dc', "http://purl.org/dc/elements/1.1/");
        svgResult.setAttribute('xmlns:cc', "http://creativecommons.org/ns#");
        svgResult.setAttribute('xmlns:rdf', "http://www.w3.org/1999/02/22-rdf-syntax-ns#");
        svgResult.setAttribute('xmlns:svg', "http://www.w3.org/2000/svg");
        svgResult.setAttribute('xmlns', "http://www.w3.org/2000/svg");
        svgResult.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink");
        svgResult.setAttribute('xmlns:sodipodi', "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd");
        svgResult.setAttribute('xmlns:inkscape', "http://www.inkscape.org/namespaces/inkscape");
        svgResult.setAttribute('xml:space', "preserve");
        svgResult.setAttribute('version', '1.1');
        
        svgResult.appendChild(qrCodeBackground);
        svgResult.appendChild(qrCodeGroup);
        svgResult.appendChild(symbolGroup);

        this.qrcodeResultDom.appendChild(svgResult);
    }

    update(options) {
        return new Promise((resolve, reject) => {
            if (!this.options) 
                this.options = {};
            if (!this.options.url)
                this.options.url = undefined
            if (!this.options.color)
                this.options.color = undefined
            if (!this.options.symbol)
                this.options.symbol = {}

            if (options && options.color) {
                this.options.color = options.color;
            }

            if (options && options.url && options.url != this.options.url) {
                this.options.url = options.url;
                createSvgQrCode(options.url).then((svgString) => {
                    this.baseQrCodeString = svgString;
                    this.update(options).then(() => {
                        resolve()
                    })
                });
                return;
            }

            if (options && options.symbol && options.symbol.id !== this.options.symbol.id) {
                if (this.options.symbol == undefined) this.options.symbol = {}
                this.options.symbol.id = options.symbol.id;
                
                const symbolIndexOf = symbols.map(e => e.id).indexOf(this.options.symbol.id);
                const symbol = symbols[symbolIndexOf];
                this.options.symbol.backgroundHull = symbol.backgroundHull ? symbol.backgroundHull : symbol.hitform;
                this.options.symbol.colors = symbol.colors;
                
                const symbolUrl = '/static/symbols/' + symbol['filename'];
                loadSvg(symbolUrl).then((svgString) => {
                    this.symbolSvgString = svgString;
                    this.symbolColoredString = colorSvgString(this.symbolSvgString, symbol.colors).string;
                    this.update().then(() => { resolve() });
                });
                return;
            }

            if (options && options.symbol && options.symbol.colors && colorsChanged(this.options.symbol.colors, options.symbol.colors)) {
                this.options.symbol.colors = options.symbol.colors;
                this.symbolColoredString = colorSvgString(this.symbolSvgString, options.symbol.colors).string;                
            }
                
            this.mergeSvg();
            resolve();             
        });
    }


    toSvg() {
        if (!this.qrcodeResultDom) return '<svg></svg>';
        return this.qrcodeResultDom.innerHTML;
    }

    destroy() {

    }


}